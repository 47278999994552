<template>
  <v-app-bar
    app
    :color="!darkMode ? 'white' : ''"
    clipped-left
    :flat="false"
    :elevate-on-scroll="true"
  >
    <v-avatar
      style="top:-5px"
      tile
      class="d-inline-flex mr-3 mt-3"
      size="45"
    >
      <v-img
        :src="darkMode ? 'https://cdn.appedr.com/img/circle-white.png' : 'https://cdn.appedr.com/img/circle-blue.png'"
        contain
        alt="logo"
      />
    </v-avatar>
    <v-toolbar-title class="text-uppercase font-weight-black">{{$t('base.checkin')}}<v-icon :color="!darkMode ? 'primary' : 'white'">mdi-check-circle</v-icon>
    </v-toolbar-title>

    <v-spacer />

    <v-btn
      class="mx-2"
      text
      link
      :to="`/check-in/${collegeCode}/dashboard`"
    >
      <v-icon>mdi-home</v-icon>
      <span class="d-none d-sm-flex">{{$t('base.home')}}</span>
    </v-btn>
    <v-btn
      class="mx-2"
      text
      link
      :to="`/check-in/${collegeCode}/qr`"
    >
      <v-icon>mdi-qrcode</v-icon>
      <span class="d-none d-sm-flex">{{$t('nav.generateQrCheckinOut')}}</span>
    </v-btn>

    <!-- <v-btn
      class="mx-2"
      text
      link
      :to="`/check-in/${collegeCode}/history`"
    >
      <v-icon>mdi-chart-areaspline</v-icon>
      <span class="d-none d-sm-flex">{{$t('base.checkinHistory')}}</span>
    </v-btn> -->
    <!-- <v-btn
      class="mx-2"
      text
      link
      :to="`/check-in/${collegeCode}/checkin`"
    >
      <v-icon>mdi-qrcode-scan</v-icon>
      <span class="d-none d-sm-flex">Checkin</span>
    </v-btn> -->
    <core-locale />
    <v-switch
      class="mx-2"
      hide-details
      v-model="darkMode"
      :label="darkMode ? $t('nav.darkMode') : $t('nav.lightMode')"
    />

  </v-app-bar>
</template>

<script>
import { defineComponent, watch, onMounted, ref } from "@vue/composition-api";
import useSiteSetting from "@/composition/UseSiteSetting";
export default defineComponent({
  components: {
    CoreLocale: () => import("@/views/App/components/core/Locale")
  },
  setup(props, { root }) {
    const { darkMode, setDarkMode } = useSiteSetting(root);
    const { $route } = root;
    const collegeCode = ref(null);
    onMounted(() => {
      collegeCode.value = $route.params.collegeCode;
    });
    watch(darkMode, (val, prevVal) => {
      if (val !== undefined && prevVal !== undefined) {
        setDarkMode(val);
      }
    });

    return {
      darkMode,
      collegeCode
    };
  }
});
</script>
